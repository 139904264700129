import { render, staticRenderFns } from "./DoctorSalesStat.vue?vue&type=template&id=755ec2ad&scoped=true&"
import script from "./DoctorSalesStat.vue?vue&type=script&lang=js&"
export * from "./DoctorSalesStat.vue?vue&type=script&lang=js&"
import style0 from "./DoctorSalesStat.vue?vue&type=style&index=0&id=755ec2ad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755ec2ad",
  null
  
)

export default component.exports